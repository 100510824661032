// @flow
import { hot } from "react-hot-loader/root";
import PageRoutes from "./pageRoutes";
import { useLocation } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import { Suspense, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "./_store/reducer/rootReducer";
import { save } from "./_store/actions/user";
import Cookies from "js-cookie";
import { setPathPage } from "./_store/actions/pathPage";
import { SUCCESS_MESSAGE } from "./_store/constants/message";
import { sidebarItemsMap } from "./widgets/SideBar/sidebarItems";
import styles from "./App.module.scss";
import { useSessionStorageTemporary } from "useSessionStorageTemporary";
import Loader from './widgets/loader'

const App = () => {
  const [open, setOpen] = useState<boolean>(false);
  const location = useLocation();
  const dispatch = useDispatch();
  // TODO
  const temp = useSessionStorageTemporary();  

  const [messageId, setMessageId] = useState<string>("");
  const [user, setUsers] = useState<any>(null);

  const [message] = useSelector((state: RootState) => {
    if (state.message.id && messageId !== state.message.id) {
      setMessageId(state.message.id);
      setOpen(true);
    }
    return [state.message];
  });

  useSelector((state: RootState) => {
    if (state.user.user && user !== state.user.user) {
      setUsers(state.user.user);
    }
    return [state.user];
  });

  const handlePathChange = async () => {
    let newPathPage;
    if (user) {
      sidebarItemsMap?.get(user?.role)?.forEach((item) => {
        if (item.path === location.pathname) {
          newPathPage = { itemPathPage: item.path };
          return;
        }
        item?.subItems?.forEach((subItem) => {
          if (subItem.path === location.pathname) {
            newPathPage = {
              itemPathPage: item.title,
              subItemPathPage: subItem.path,
            };
            return;
          }
        });
      });
      dispatch(setPathPage(newPathPage));
    }
  };
  useEffect(() => {
    handlePathChange();
  }, [location?.pathname]);

  useEffect(() => {
    if (localStorage.getItem("user") && sessionStorage.getItem('token')) {
      dispatch(save(JSON.parse(localStorage.getItem("user")!)));
    } else if (!localStorage.getItem("user") && !sessionStorage.getItem('token')) {
      dispatch(save(JSON.parse(localStorage.getItem("user")!)));
    }
  }, [localStorage.getItem("user"), sessionStorage.getItem('token')]);

  useEffect(() => {
    handlePathChange();
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <Snackbar
        onClose={() => setOpen(!open!)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={4000}
        open={open}
        message={message ? message.message : "message"}
        key="snackbar"
        className={`${
          message.type === SUCCESS_MESSAGE
            ? styles.snackbar_success
            : styles.snackbar_error
        }`}
        style={{ left: "47%" }}
      />
      <PageRoutes />
    </Suspense>
  );
};

export default hot(App);
