
import jwt_decode from "jwt-decode";

export const useSessionStorageTemporary = () => {
    // Role instructor
    // sessionStorage.setItem("token", "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjIyNCIsIm9yZ1VuaXRJZCI6IjY4MTNfNjgxNCIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6Ikluc3RydWN0b3IiLCJleHAiOjE2NDkwNzkyODksImlzcyI6ImFjbG0uc2h1ZmZsZWV4Y2hhbmdlLmNvbSIsImF1ZCI6ImFjbG0uc2h1ZmZsZWV4Y2hhbmdlLmNvbSJ9.o1-Iuxd1YouyqpErJM8QrjJRX7T9vVd_C_zjy8QKXB8");
    // Role Student
    // sessionStorage.setItem("token", "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjIyMyIsIm9yZ1VuaXRJZCI6IjY4MTNfNjgxNCIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IlN0dWRlbnQiLCJleHAiOjE2NDkwNzg2NjgsImlzcyI6ImFjbG0uc2h1ZmZsZWV4Y2hhbmdlLmNvbSIsImF1ZCI6ImFjbG0uc2h1ZmZsZWV4Y2hhbmdlLmNvbSJ9.grLJ2Zf7_netznWxa5LWpCSMe6aCkEy8KXEEbHRm1GA");
    const token = sessionStorage.getItem("token") || "";
    const decoded: any = jwt_decode(token);
    let role = "";
    Object.keys(decoded).map(key => { 
      if (key.includes("role")) {
        role = decoded[key];
      }
    });
    const userDetails = {
      role,
      email: "example",
      lightThemePreference: "",
      name: "user",
    };
    localStorage.setItem("user", JSON.stringify(userDetails));
    return {
        token,
        userDetails
    }
}