import {
  SideAccount
} from "../../utilities/images/icons/index";
import { path } from "../../pageRoutes/routers";
import { role } from "../../types/index";
const superAdmin = [
  {
    Icon: SideAccount,
    title: "Sessions",
    path: path.Sessions,
  },
];

const sidebarItemsMap = new Map<any, any>();
sidebarItemsMap.set(role.Instructor, superAdmin);
sidebarItemsMap.set(role.Student, superAdmin);

export { sidebarItemsMap };
