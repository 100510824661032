import { lazy } from 'react';
import { role } from "../types/index";
const Students = lazy(() => import('containers/students'));
const DashBoard = lazy(() => import('containers/instructor/dashboard'));
const Instructor = lazy(() => import('containers/instructor'));
const List = lazy(() => import('containers/students/list'));
const Reports = lazy(() => import('containers/instructor/reports'));
const ViewDetails = lazy(() => import('containers/instructor/viewDetails'));
const ViewEncList = lazy(() => import('containers/instructor/viewEncList'));

enum path {
  Index = "/",
  CustomerHome = "/home",
  Login = "/login",
  Sessions = "/session",
  UserReports = "/usereports",
  Students = "/students",
  Instructor = "/instructor",
  InstructorEnc = "/instructor/:id", 
  InstructorEncDetail = "/instructor/:id/:encId", 
  List = "/list",
  Reports= "/reports",
  ViewDetails="/viewdetails",
  NewHours="/newhours"
}

interface RouteTemplate {
  path: string;
  component: any;
  type?: string;
  role?: string;
}

const unsecureRoutes: RouteTemplate[] = [ 
  {
    path: `${path.CustomerHome}`,
    component: DashBoard,
  },
  {
    path: `${path.Instructor}`,
    component: Instructor,
  },
  {
    path: `${path.List}`,
    component: List,
  },
  {
    path: `${path.Reports}`,
    component: Reports,
  },
  {
    path: `${path.ViewDetails}`,
    component: ViewDetails,
  }
];
const InstructorRoutes: RouteTemplate[] = [
  {
    path: `${path.Index}`,
    component: Instructor,
  },
  {
    path: `${path.Instructor}`,
    component: Instructor,
  },
  {
    path: `${path.InstructorEnc}`,
    component: ViewEncList,
  },
  {
    path: `${path.InstructorEncDetail}`,
    component: ViewDetails,
  },
];
const studentRoutes: RouteTemplate[] = [
  {
    path: `${path.Index}`,
    component: Students,
  },
  {
    path: `${path.Students}`,
    component: Students,
  }
];


const securedRoutesMap = new Map<any, any>();
securedRoutesMap.set(role.Instructor, InstructorRoutes);
securedRoutesMap.set(role.Student, studentRoutes);
export { path, role, unsecureRoutes, securedRoutesMap };
