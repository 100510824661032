import styles from './index.module.scss';

const Loader = () => {

  return (<>
    <div className={styles.loader}>
      <div className={styles.loaderBox}>
        <div className={styles.loaderRound}></div>
        &nbsp; &nbsp;ACLM
      </div>
    </div>
  </>
  );
};

export default Loader;
