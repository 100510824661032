import { combineReducers } from 'redux';
import user from './user'
import message from './message'
import pagePath from './pagePath'
const reducer = combineReducers({
  user,
  message,
  pagePath
})
export type RootState = ReturnType<typeof reducer>
export default reducer